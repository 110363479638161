import { ConflictId } from "../atomic-operations/AtomicOperationControllerTypes.js";
import {
  APPLY_OPERATION,
  QUEUE_OPERATION,
} from "../atomic-operations/operationConstants.js";

/* eslint-disable no-console */
interface AppliedAOEntry {
  conflictId: ConflictId;
  revision: number | QUEUE_OPERATION | APPLY_OPERATION;
}

function formatEntry(entry: AppliedAOEntry): string {
  return `conflictId: ${entry.conflictId}, revision: ${entry.revision}`;
}

/**
 * Circular buffer of recent trace ids to surface in the UI
 */
class AppliedAOLog {
  private readonly maxEntries: number;
  private readonly entries: AppliedAOEntry[] = [];

  private shouldLog = false;

  constructor({ maxEntries }: { maxEntries: number }) {
    this.maxEntries = maxEntries;
  }

  public push(entry: AppliedAOEntry): void {
    this.entries.push(entry);
    if (this.entries.length > this.maxEntries) {
      this.entries.shift();
    }

    if (this.shouldLog) {
      // eslint-disable-next-line no-console
      console.log(formatEntry(entry));
    }
  }

  public getAll(): readonly AppliedAOEntry[] {
    return this.entries;
  }

  public getAllFormatted(): string {
    return this.getAll()
      .map((entry) => formatEntry(entry))
      .join("\n");
  }

  public printAllFormatted(): void {
    // eslint-disable-next-line no-console
    console.log(this.getAllFormatted());
  }

  public toggleLogging = (): void => {
    this.shouldLog = !this.shouldLog;
    // eslint-disable-next-line no-console
    console.log("logging enabled for connection log");
  };
}

export const APPLIED_AO_LOG = new AppliedAOLog({
  maxEntries: 25,
});
